<template>
  <div class="envelope">
    <div class="profile profile--team">
      <div class="profile__wrapper">
        <div class="profile__content">
          <div class="profile__photo">
            <img src="@/assets/img/team/team-logo.svg" alt="Team logo">
          </div>
          <div class="profile__info">
            <div class="profile__name">{{ team.name }}</div>
            <router-link v-if="team.is_admin" :to="{ name: 'team-edit' }" class="profile__edit">
              <img src="@/assets/img/profile/profile-edit.svg" alt="">
              Редактировать
            </router-link>
          </div>
        </div>
      </div>
      <div class="profile__contacts">
        <div class="profile-contacts">
          <div class="profile-contacts__item">
            <img src="@/assets/img/profile/dob-icon.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Дата регистрации:</div>
              <div class="profile-contact__text">{{ team.created_at }}</div>
            </div>
          </div>
          <div class="profile-contacts__item">
            <img src="@/assets/img/team/team-members.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Участников:</div>
              <div class="profile-contact__text">{{ team.members_count }}</div>
            </div>
          </div>
          <div v-if="team.region_text" class="profile-contacts__item">
            <img src="@/assets/img/team/location-big-blue.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Регион:</div>
              <div class="profile-contact__text">{{ team.region_text }}</div>
            </div>
          </div>
          <div v-if="team.org_text" class="profile-contacts__item profile-contacts__item--shrink">
            <img src="@/assets/img/team/org-big-blue.svg" alt="" class="profile-contacts__icon">
            <div class="profile-contacts__info">
              <div class="profile-contact__text profile-contact__text--strong">Организация:</div>
              <div class="profile-contact__text">{{ team.org_text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content--home">
<!--      <div class="content__title content__title&#45;&#45;party">-->
<!--        <h1 class="title title&#45;&#45;big title&#45;&#45;theme">Список моих команд</h1>-->
<!--        <router-link :to="{ name: 'team-create' }" class="button button&#45;&#45;mini">Создать команду</router-link>-->
<!--      </div>-->
      <div class="content__tabs">
        <div class="tabs-list">
          <ul class="tabs-list__body">
            <li class="tabs-list__item">
              <router-link :to="{ name: 'team-detail' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Команда
              </router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'team-invites' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Заявки
              </router-link>
            </li>
            <li class="tabs-list__item">
              <router-link :to="{ name: 'team-invitations' }" exact-active-class="tabs-list__link--active"
                           class="tabs-list__link">Приглашения
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <router-view @changeAdmin="fetchTeam" class="content__body" :team="team" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamLayout',
  data() {
    return {
      team: {},
    }
  },
  mounted() {
    this.fetchTeam()
  },
  methods: {
    fetchTeam() {
      this.$store.dispatch('team/GET_DATA', this.$route.params.id)
        .then(response => {
          this.team = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    }
  }
}
</script>
<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
